import { render, staticRenderFns } from "./PeriodRiskManage.vue?vue&type=template&id=6085aa6b&scoped=true&"
import script from "./PeriodRiskManage.vue?vue&type=script&lang=js&"
export * from "./PeriodRiskManage.vue?vue&type=script&lang=js&"
import style0 from "./PeriodRiskManage.vue?vue&type=style&index=0&id=6085aa6b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6085aa6b",
  null
  
)

export default component.exports